import { useState, useEffect } from "react";
import UploadImage from "./UploadImage";
import axios from "axios";
import { Box, Grid, Typography } from "@mui/material";
import { useAuth } from "../../../../../auth/authContext";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { Grade } from "@material-ui/icons";
import DisableLicence from "../../../../../common/DisableLicence";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const ImagePage = () => {
  const { token, cartData } = useAuth();
  const [initialImages, setInitialImages] = useState({});
  const [modifiedImages, setModifiedImages] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    // Load existing images from cartData if available
    if (cartData) {
      const images = {
        bride_avatar: cartData.bride_avatar || null,
        groom_avatar: cartData.groom_avatar || null,
        background: cartData.background || null,
      };
      setInitialImages(images);
    }
  }, [cartData]);

  const handleImageUpload = (name, imageFile) => {
    setModifiedImages((prevState) => ({
      ...prevState,
      [name]: imageFile, // Save the modified image (can be a file or null)
    }));
  };

  const handleSendImages = async () => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      // Add all modified images, including null values
      for (const name in modifiedImages) {
        if (modifiedImages[name] === null) {
          // Append an empty value for null (key must exist)
          formData.append(name, "");
        } else if (modifiedImages[name]) {
          // Append the file if it exists
          formData.append(name, modifiedImages[name]);
        }
      }

      const response = await axios.post(
        `${BASE_URL}/api/licence/current`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(response?.data?.message);
      setIsUploading(false);
      setModifiedImages({}); // Reset modified images after successful save
    } catch (error) {
      setIsUploading(false);
      toast.error(error?.response?.data?.message || "خطا در ذخیره تغییرات");
    }
  };

  return (
    <Box className="formInformation">
      {Number(cartData?.licence_id) === 1 ? <DisableLicence /> : ""}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">عکس</Typography>
          <Typography variant="inherit">
            این بخش اختیاری است. هر آیتمی که نیاز دارید آپلود کنید.{" "}
          </Typography>
          <Typography variant="inherit">
            * حجم عکس‌ها باید کمتر از 500kb باشد.
          </Typography>
          <Typography variant="inherit">
            کاهش حجم عکس:{" "}
            <a href="https://compressjpeg.com/" target="_blank">
              compressjpeg.com
            </a>
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          item
          xs={12}
          md={4}
        >
          <Typography variant="h6">عروس</Typography>
          <UploadImage
            initialImageUrl={
              initialImages.bride_avatar
                ? `https://aroosi.online/storage/${initialImages.bride_avatar}`
                : null
            }
            onImageUpload={(imageFile) =>
              handleImageUpload("bride_avatar", imageFile)
            }
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          item
          xs={12}
          md={4}
        >
          <Typography variant="h6">داماد</Typography>
          <UploadImage
            initialImageUrl={
              initialImages.groom_avatar
                ? `https://aroosi.online/storage/${initialImages.groom_avatar}`
                : null
            }
            onImageUpload={(imageFile) =>
              handleImageUpload("groom_avatar", imageFile)
            }
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          item
          xs={12}
          md={4}
        >
          <Typography variant="h6">پس زمینه</Typography>
          <UploadImage
            initialImageUrl={
              initialImages.background
                ? `https://aroosi.online/storage/${initialImages.background}`
                : null
            }
            onImageUpload={(imageFile) =>
              handleImageUpload("background", imageFile)
            }
          />
        </Grid>

        <Grid xs={12} mt={2}>
          {isUploading && (
            <Box
              sx={{
                marginTop: "8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
              <Typography sx={{ color: "#3f51b5", marginTop: "8px" }}>
                در حال انجام ...
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
          item
          xs={12}
        >
          <button
            onClick={handleSendImages}
            className="saveInformation"
            type="submit"
          >
            ذخیره
          </button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ImagePage;
