import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

export const getLicenceInfo = () =>
  axios
    .get(`${BASE_URL}/api/licence`) 
    .then((response) => {
      return response.data; 
    })
    .catch((error) => {
      console.error("Error:", error.response || error.message);
      throw error;
    });
