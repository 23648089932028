import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CartProvider from "./Providers/CartProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
// import FindLocationPage from './pages/Bloges/FindLocation';
// import PlanPage from './pages/Plan/PlanPage';
// import { handleLogin } from './auth/auth';
// import PrivateRoutes from './routing/privateRout';
import { GeneralPrivateRoutes, GeneralPublicRoutes } from "./routing/common";
import { HelmetProvider } from "react-helmet-async";
import AuthProvider from "./auth/authContext";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 5,
    });
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <AuthProvider>
          <CartProvider>
            <ToastContainer />
            <GeneralPublicRoutes />
            <GeneralPrivateRoutes />
          </CartProvider>
        </AuthProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;
