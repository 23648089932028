import React, { useState, useEffect } from "react";
import { Box, Typography, Stack } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import * as data from "../../../../data.js";
import axios from "axios";
import { useAuth } from "../../../../auth/authContext.js";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

// Initialize Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination]);

const Section6Page = ({ cartData }) => {
  const { token } = useAuth();
  const [comments, setComments] = useState();

  useEffect(() => {
    // axios
    //   .get(`${BASE_URL}/api/licence/current`, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   .then((response) => {
    //     const responseData = response?.data?.wedding?.mementos;
    //     const updatedSelectedRows =
    //       responseData
    //         ?.filter((item) => Number(item?.show))
    //         ?.map((item) => item?.id) || [];
    //     setComments(responseData);
    //     // toast.success(response?.data?.message);
    //   })
    //   .catch((error) => {
    //     // toast.error(error?.response?.data?.message);
    //   });

    const memento = cartData?.mementos;
    const filterMemento = memento?.filter((item) => Number(item?.show)) || [];
    setComments(filterMemento);
  }, [cartData]);

  const sliderSettings = {
    freeMode: false,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    // navigation: true,
    className: "mySwiper",
    slidesPerView: 4,
    spaceBetween: 10,
    pagination: {
      clickable: true,
    }, // Move pagination inside its own object
    modules: [Pagination],
    breakpoints: {
      // 1800: { slidesPerView: 2 },
      // 1600: { slidesPerView: 2 },
      // 1400: { slidesPerView: 2 },
      1200: { slidesPerView: 3 },
      900: { slidesPerView: 2 },
      600: { slidesPerView: 2 },
      500: { slidesPerView: 1 },
      1: { slidesPerView: 1 },
    },
  };

  function rgba(r, g, b, a) {
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }

  return (
    <Box
      mt={1}
      className="container"
      mb={2}
      px={0}
      sx={{
        "& .swiper-slide": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          borderRadius: "8px",
          padding: "15px",
          margin: "15px 0",
          border: "1px solid #90a171",
          // boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
        },
      }}
    >
      <Swiper {...sliderSettings}>
        {/* {comments?.map((comment, index).filter(comment[index]?.show) => ( */}
        {comments?.map((comment, index) => (
          <SwiperSlide key={index}>
            <Stack
              direction="column"
              sx={{
                width: "100%",
                // '&:hover': {
                //   '& .imagebox': {
                //     boxShadow: '0px 0px 5px 0px rgba(255,255,255,0.43)',
                //   },
                // },
              }}
            >
              {/* <Box
                className="imagebox"
                sx={{
                  width: 230,
                  height: 230,
                  borderRadius: 8,
                  overflow: 'hidden',
                  transition: 'all 300ms ease-in-out',
                  display: 'block',
                  // ' img': {
                  //   width: '100%',
                  //   height: '100%',
                  //   objectFit: 'cover',
                  //   display: 'block',
                  // },
                }}
              >
              </Box> */}
              <img
                src="/asset/media/pic/cart9/cart9-4.png"
                className="cardImage1"
                alt=""
              />
              <Typography variant="h6" fontWeight={500} mt={1}>
                {comment?.name}
              </Typography>
              <hr style={{color:"#90a171",opacity:"1"}}/>
              <Typography
                mt={1}
                variant=""
                fontWeight={300}
                textAlign="justify"
              >
                {comment?.text}
              </Typography>
            </Stack>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Section6Page;
