import Input from "../../common/input";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./login.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Toast, toast } from "react-toastify";
import axios from "axios";
import { useAuth } from "../../auth/authContext";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const initialValues = {
  mobile: "",
  password: "",
};

const validationSchema = Yup.object({
});

const LoginFormAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  // const redirectPath = location.state?.redirectPath || "/dashboard";

  const onSubmit = (values) => {
    axios
      .post(`${BASE_URL}/api/auth/verify-from-admin`, values)
      .then((response) => {
        toast.success(response?.data?.message);
        login(response?.data?.status, response?.data?.token);

        // هدایت به مسیر بازگشت
        navigate('/', { replace: true });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnMount: true,
  });

  return (
    <section className="loginPage">
      <Link to="/" className="backBtn">
        back
      </Link>

      <div className="loginPageOut">
        <img
          alt=""
          className="flowerLeft"
          src="/asset/media/pic/login-flower1.webp"
        />
        <img
          alt=""
          className="flowerRight"
          src="/asset/media/pic/login-flower2.webp"
        />

        <form className="loginPageIn" onSubmit={formik.handleSubmit}>
          <div className="title">
            <h1>Login Admin</h1>
            <img
              alt=""
              className="flower"
              src="/asset/media/pic/login-title.webp"
            />
          </div>

          <Input
            formik={formik}
            name="mobile"
            label="mobile"
            type="tel"
          ></Input>
          <Input
            formik={formik}
            name="password"
            label="password"
            type="text"
          ></Input>

          <button className="login" type="submit" disabled={!formik.isValid}>
            login
          </button>
        </form>
      </div>
    </section>
  );
};

export default LoginFormAdmin;
