import jalaliMoment from "jalali-moment";
import numberToPersian from "../../../../common/numberToPersian";

const Section1Page = ({ cartData }) => {
  const persianDate = jalaliMoment(cartData?.date || "2025-01-26")
    ?.locale("fa")
    ?.format("YYYY/MM/DD");

  return (
    <div className="sectionOne">
      <div
        className="backgroundOverlay"
        style={{
          backgroundImage: `url(https://api.aroosi.online/storage/${cartData?.background})`,
        }}
      ></div>
      <img
        src="/asset/media/pic/cart1/cart2-1.png"
        className="cardImage1"
        alt=""
      />
      <img
        src="/asset/media/pic/cart1/cart2-2.png"
        className="cardImage2"
        alt=""
      />

      <div className="imgBox">
        {cartData?.bride_avatar && (
          <img
            src={`https://api.aroosi.online/storage/${cartData?.bride_avatar}`}
            className="arosImage"
            alt=""
            data-aos="fade-up"
            data-aos-delay="100"
            loading="lazy"
          />
        )}
        {cartData?.groom_avatar && (
          <img
            src={`https://api.aroosi.online/storage/${cartData?.groom_avatar}`}
            className="damadImage"
            alt=""
            data-aos="fade-up"
            data-aos-delay="200"
            loading="lazy"
          />
        )}
      </div>

      {cartData ? (
        <>
          {Number(cartData?.show_eng_names) ? (
            <div className="namesEng">
              <div className="names">
                <span
                  className="aros"
                  data-aos="fade-left"
                  data-aos-delay="100"
                >
                  {cartData?.bride_name_eng}
                </span>
                <span className="and">&</span>
                <span
                  className="damad"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  {cartData?.groom_name_eng}
                </span>
              </div>
              <div className="FamilyNames">
                <span
                  className="aros"
                  data-aos="fade-left"
                  data-aos-delay="100"
                >
                  {cartData?.bride_lastname_eng}
                </span>
                <span className="and">&</span>
                <span
                  className="damad"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  {cartData?.groom_lastname_eng}
                </span>
              </div>
            </div>
          ) : (
            <>
              <div className="names">
                <span
                  className="aros"
                  data-aos="fade-left"
                  data-aos-delay="100"
                >
                  {cartData?.bride_name}
                </span>
                <span className="and">و</span>
                <span
                  className="damad"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  {cartData?.groom_name}
                </span>
              </div>
              <div className="FamilyNames">
                <span
                  className="aros"
                  data-aos="fade-left"
                  data-aos-delay="100"
                >
                  {cartData?.bride_lastname}
                </span>
                <span className="and">و</span>
                <span
                  className="damad"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  {cartData?.groom_lastname}
                </span>
              </div>
            </>
          )}

          <p className={`text ${Number(cartData?.align) ? "right" : "center"}`}>
            {cartData?.description}
          </p>
          {cartData?.date && (
            <>
              <p
                className="detail"
                // data-aos="fade-up"
                // data-aos-delay="100"
                style={{ marginTop: "25px" }}
              >
                {jalaliMoment(cartData?.date)?.locale("fa")?.format("dddd")}
              </p>
              <p
                className="detail"
                // data-aos="fade-up"
                // data-aos-delay="100"
                style={{ marginTop: "5px" }}
              >
                <span>
                  {numberToPersian(
                    jalaliMoment(persianDate, "jYYYY/jMM/jDD")?.format("jDD")
                  )}
                </span>
                /
                <span>
                  {jalaliMoment(persianDate, "jYYYY/jMM/jDD")?.format("jMMMM")}
                </span>
                /
                <span>
                  {numberToPersian(
                    jalaliMoment(persianDate, "jYYYY/jMM/jDD")?.format("jYYYY")
                  )}
                </span>
              </p>
            </>
          )}
          <p
            className="detail"
            // data-aos="fade-up"
            // data-aos-delay="200"
            style={{ marginTop: "5px" }}
          >
            {cartData?.from_time
              ? numberToPersian(cartData?.from_time?.substring(0, 5))
              : ""}{" "}
            -{" "}
            {cartData?.to_time
              ? numberToPersian(cartData?.to_time?.substring(0, 5))
              : ""}
          </p>
          <p
            className="detail"
            // data-aos="fade-up"
            // data-aos-delay="300"
            style={{ marginTop: "5px" }}
          >
            به صرف {cartData?.foods}
          </p>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Section1Page;
