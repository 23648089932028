import { Box, Grid, Typography, Button } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../../../../auth/authContext";
import { Link } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const CopyPublicCardLink = () => {
  const { token, cartData } = useAuth();
  const [publicCard, setPublicCard] = useState([]);

  const code = publicCard?.default_cart?.code;
  const slug = publicCard?.wedding?.slug;
  const link = "https://aroosi.online/" + slug + "/" + code;

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/licence/current`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPublicCard(response?.data);
      })
      .catch((error) => {
        // Handle error
      });
  }, [cartData]);

  const CopyLink = () => {
    return () => {
      if (!code || !slug) {
        toast.error("لینک موجود نیست!");
        return;
      }

      navigator.clipboard
        .writeText(link)
        .then(() => {
          toast.info("لینک با موفقیت کپی شد!");
        })
        .catch((err) => {
          toast.error("خطا در کپی کردن لینک!");
          console.error("Clipboard copy failed: ", err);
        });
    };
  };

  return (
    <Box className="formInformation">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">لینک کارت عروسی</Typography>
          <Typography variant="p" mb={1}>
            اگر از پکیج رایگان استفاده میکنید و یا به یک کارت عمومی احتیاج
            دارید، بعد از پر کردن
            <span style={{ color: "red" }}> اطلاعات کارت و انتخاب قالب </span>،
            در این قسمت یک لینک در اختیار شما قرار داده میشود آن را کپی کرده و
            برای مهمان ها ارسال کنید.
          </Typography>
          <Typography style={{ color: "red" }}>
            * این کارت به دلیل اینکه آمار دقیقی داشته باشید، دارای نظرسنجی و
            یادبود نمی باشد.
          </Typography>
        </Grid>
        {publicCard?.wedding?.template_id ? (
          <>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
              item
              xs={12}
            >
              <button
                style={{
                  padding: "5px 20px",
                  border: "1px solid #474787",
                  color: "#474787",
                  backgroundColor: "#47478720",
                  marginBottom: "10px",
                }}
                onClick={CopyLink()}
              >
                کپی لینک
              </button>
              <a href={link} target="_blank" style={{ color: "#474787" }}>
                {link}
              </a>
            </Grid>
            {/* <Typography >
            * اگر میخوایید لینک رو <span style={{ color: "red" }}> پیامک </span> کنید این  لینک رو کپی کنید. ولی توصیه میکنیم از پنل پیامکی خودمون استفاده کنید.
          </Typography>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
              item
              xs={12}
            >
              <button
                style={{
                  padding: "5px 20px",
                  border: "1px solid #474787",
                  color: "#474787",
                  backgroundColor: "#47478720",
                  marginBottom: "10px",
                }}
                onClick={CopyLink()}
              >
                کپی لینک
              </button>
              <a href={link} target="_blank" style={{ color: "#474787" }}>
                {link}
              </a>
            </Grid> */}
          </>
        ) : (
          <Grid sx={{ display: "flex", justifyContent: "end" }} item xs={12}>
            <Typography variant="h6" sx={{ color: "#474787", opacity: "0.4" }}>
              لینک
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CopyPublicCardLink;
