import { Grid, Typography } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import PersianDatePicker from "./PersianDatePicker";
import jalaliMoment from "jalali-moment";
import { useAuth } from "../../../../../auth/authContext";
import { toast } from "react-toastify";
import axios from "axios";
import convertToEnglishNumbers from "../../../../../common/convertToEnglishNum";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const dataRanges = [
  {
    disabled: true,
    start: jalaliMoment().add(-1000, "days"),
    end: jalaliMoment().add(0, "days"),
  },
  //   {
  //     color: '#00BCD4',
  //     start:jalaliMoment(),
  //     end:jalaliMoment().add(3,'days')
  //   },
];

const DatePage = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const { token, fetchCartData, cartData } = useAuth();

  useEffect(() => {
    if (cartData?.date) setSelectedDate(jalaliMoment(cartData?.date));
  }, [cartData?.date]);

  const handleDateChange = (value) => {
    setSelectedDate(value);
    // if (selectedDate && isDateWithinDisabledRange(selectedDate)) {
    //     setSelectedDate(value);
    // }
  };
  const isDateWithinDisabledRange = (date) => {
    for (const range of dataRanges) {
      if (date.isBetween(range.start, range.end, null, "[]")) {
        return true;
      }
    }
    return false;
  };

  const initialValues = {
    fromHour: cartData?.from_time?.substring(0, 5) || "",
    toHour: cartData?.to_time?.substring(0, 5) || "",
    eat: cartData?.foods || "",
    selectedDate: "",
  };

  const validationSchema = Yup.object({
    fromHour: Yup.string().required("زمان مورد نظر را واردکنید."),
    toHour: Yup.string().required("زمان مورد نظر را واردکنید."),
    eat: Yup.string().required("فیلد را پر کنید."),
    selectedDate: Yup.string(),
  });

  const handleSubmit = (values) => {
    values.selectedDate = selectedDate?.format("jYYYY/jMM/jDD")
      ? selectedDate?.format("jYYYY/jMM/jDD")
      : "-";

    axios
      .post(
        `${BASE_URL}/api/licence/current`,
        {
          date: values?.selectedDate,
          from_time: convertToEnglishNumbers(values?.fromHour),
          to_time: convertToEnglishNumbers(values?.toHour),
          foods: values?.eat,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message);
        fetchCartData();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <Form className="formInformation">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">تاریخ و ساعت مراسم:</Typography>
          </Grid>

          <Grid sx={{ display: "flex", flexDirection: "column" }} item xs={6}>
            <label htmlFor="fromHour"> از ساعت</label>
            <Field
              type="text"
              id="fromHour"
              name="fromHour"
              placeholder="19:30"
            />
            <ErrorMessage
              name="fromHour"
              component="p"
              style={{ color: "red", fontSize: "12px" }}
            />
          </Grid>
          <Grid sx={{ display: "flex", flexDirection: "column" }} item xs={6}>
            <label htmlFor="toHour"> تا ساعت</label>
            <Field type="text" id="toHour" name="toHour" placeholder="23:30" />
            <ErrorMessage
              name="toHour"
              component="p"
              style={{ color: "red", fontSize: "12px" }}
            />
          </Grid>
          <Grid sx={{ display: "flex", flexDirection: "column" }} item xs={6}>
            <label htmlFor="eat">به صرف :</label>
            <Field type="text" id="eat" name="eat" placeholder="شام و شیرینی" />
            <ErrorMessage
              name="eat"
              component="p"
              style={{ color: "red", fontSize: "12px" }}
            />
          </Grid>

          <Grid sx={{ display: "flex", flexDirection: "column" }} item xs={6}>
            <label htmlFor="selectedDate">تاریخ :</label>
            <PersianDatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dataRanges={dataRanges}
            />
            {/* {selectedDate && (
                            <p>Selected Date: {selectedDate.format('jYYYY/jMM/jDD')}</p>
                        )} */}
            {/* <ErrorMessage name="selectedDate" component="p" style={{ color: "red", fontSize: "12px" }} /> */}
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "15px",
            }}
            item
            xs={12}
          >
            <button className="saveInformation" type="submit">
              ذخیره
            </button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default DatePage;
