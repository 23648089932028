import { useNavigate } from "react-router-dom";
import "./PlanSmsPage.css";
import { useAuth } from "../../../../auth/authContext";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { formatNumberWithCommas } from "../../../../Utils/formatNumberWithCommas";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const PlanSms = () => {
  const navigate = useNavigate();
  const { token, isAuthenticated, cartData } = useAuth();
  const [smaPlan, setSmsPlan] = useState();

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/sms-packages`)
      .then((response) => {
        setSmsPlan(response?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const addPlanHandler = (id) => {
    if (!isAuthenticated) {
      navigate("/login", { state: { redirectPath: "/buy-sms" } });
      return;
    }

    const payload = {
      sms_package_id: id,
      wedding_id: cartData?.id,
    };

    axios
      .post(`${BASE_URL}/api/pay/sms`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const actionUrl = response?.data?.action;
        if (actionUrl) {
          window.location.href = actionUrl;
        } else {
          toast.error("Action URL not found");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <section className="planSmsPage">
      {smaPlan
        ?.sort((a, b) => a.count - b.count)
        .map((plan, index) => {
          return (
            <div className="plan" key={plan.id}>
              <div className="content">
                <img
                  className="iconPlan"
                  src={`/asset/media/icon/sms${index + 1}.jpg`}
                  alt={plan.title}
                />
                <h3 className="title">{plan?.name}</h3>
                <p className="cost">{formatNumberWithCommas(plan.price)} تومان </p>
                <button
                  className="buy"
                  onClick={() => addPlanHandler(plan?.id)}
                >
                  خرید
                </button>
              </div>
            </div>
          );
        })}
    </section>
  );
};

export default PlanSms;
