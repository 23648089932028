import React from "react";
import {
  Modal,
  Divider,
  IconButton,
  Box,
  Typography,
  Backdrop,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const ModalComponent: React.FC<any> = ({
  open,
  setOpen,
  children,
  title,
  divider = true,
  minHeight = "400px",
}: any) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    maxWidth: "90%",
    bgcolor: "#FFFFFF",
    p: 2,
    borderRadius: 2,
    overflow: "auto",
    maxHeight: "90%",
    height: "auto",
    minHeight,
  };
  const handleClose = () => setOpen(false);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        ".MuiBackdrop-root": { backgroundColor: "rgba(0,0,0,0.3) !important" },
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: 800, fontSize: 24 }} variant="h4">
            {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseRoundedIcon fontSize="medium" />
          </IconButton>
        </Box>
        <Divider
          sx={{
            background: "#6C6C6C",
            my: "18px",
            display: divider ? "block" : "none",
          }}
        />
        <Box>{children}</Box>
      </Box>
    </Modal>
  );
};

export default ModalComponent;
