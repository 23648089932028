import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const DisableLicence = () => {
  return (
    <Box className="licenseDisable">
      <Box sx={{ display: "flex" }}>
        <img
          alt=""
          className="imageUpdate"
          src="/asset/media/pic/icon/credit-card.png"
        />
        <Typography variant="h6" className="licenseText">
          برای استفاده از این قسمت پکیج را آپدیت کنید.
        </Typography>
      </Box>
      <Link to="/plan/2" className="licenseLink">
        آپدیت
      </Link>
    </Box>
  );
};

export default DisableLicence;
