import { useState } from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useAuth } from "../../../../auth/authContext";
import { useLocation } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const Section3Page = ({ cartData }) => {
  const { isAuthenticated, token } = useAuth();
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const isExample = pathSegments[0] === "example-card";

  const initialValues = {
    name: cartData?.mementos[0]?.name || "",
    comment: cartData?.mementos[0]?.text || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    comment: Yup.string().required(),
  });

  const handleSubmit = (values) => {
    if (isExample) {
      toast.info("ممنون از ثبت یادبود");
    } else {
      axios
        .post(
          `${BASE_URL}/api/memento`,
          {
            name: values?.name,
            text: values?.comment,
            wedding_id: cartData?.wedding_id,
            code: cartData?.code,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response?.data?.message);
          // fetchCartData();
        })
        .catch((error) => {
          if (isAuthenticated) {
            toast.error("تنها مهمان‌ها میتوانند یادبود ثبت کنند.");
          } else {
            toast.error(error?.response?.data?.message);
          }
        });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="sectionTree" data-aos="zoom-in-right">
          <div style={{ maxWidth: "250px" }}>
            <h2 className="title">یادبودها</h2>

            <Field type="text" name="name" id="name" placeholder="نام" />
            <Field as="textarea" placeholder="متن پیام" name="comment" />
          </div>

          <button type="submit" className="send">
            ارسال
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default Section3Page;
