export const blogs = [
  {
    id: "0",
    name: "one",
    desp: "lorem1",
  },
  {
    id: "1",
    name: "two",
    desp: "lorem2",
  },
  {
    id: "2",
    name: "tree",
    desp: "lorem3",
  },
];

export const plans = [
  {
    id: 1,
    image: "/asset/media/image/aros-damad.jpg",
    title: "برنز",
    details: ["شخصی سازی کارت", "لینک اختصاصی کارت", "اشتراک نامحدود لینک"],
    planOption: [
      {
        id: 1,
        option: "شخصی سازی اطلاعات کارت",
        disable: "",
      },
      {
        id: 2,
        option: "لینک عمومی کارت شما جهت اشتراک گذاری",
        disable: "",
      },
      {
        id: 3,
        option: "امکان اشتراک گذاری نامحدود لینک",
        disable: "",
      },
      {
        id: 4,
        option: "ثبت و مدیریت یادبودها",
        disable: "disable",
      },
      {
        id: 5,
        option: "امکان ساخت آلبوم عکس",
        disable: "disable",
      },
      {
        id: 6,
        option: "امکان  قرارگیری عکس تکی عروس و داماد و پس زمینه",
        disable: "disable",
      },
      {
        id: 7,
        option: "امکان ایجاد نظرسنجی (به عنوان مثال در مورد شرکت یا عدم شرکت مهمان‌ها)",
        disable: "disable",
      },
      {
        id: 8,
        option: "مدیریت مهمان ها از طریق پنل",
        disable: "disable",
      },
      {
        id: 9,
        option: "ارسال پیامک با لینک اختصاصی برای هر مهمان (۳۰۰ پیامک رایگان)",
        disable: "disable",
      },
      {
        id: 10,
        option: "اطمینان از بازدید کارت توسط هر مهمان",
        disable: "disable",
      },
      {
        id: 11,
        option:
          "اشتراک گذاری لوکیشن تالار و امکان استفاده در برنامه‌های مسیریابی",
        disable: "disable",
      },
      {
        id: 12,
        option: "استفاده از قالب‌های تشریفات",
        disable: "disable",
      },
      {
        id: 13,
        option: "پیامک یادآوری روز قبل از مراسم برای مهمان ها",
        disable: "disable",
      },
      {
        id: 14,
        option: "عدم محدودیت در تعداد کارت‌های ارسالی (۳۰۰ sms رایگان و امکان شارژ)",
        disable: "disable",
      },
      {
        id: 15,
        option: "استفاده از قالب‌های لوکس",
        disable: "disable",
      },
    ],
    off: "رایگان",
  },


  // {
  //     id: 2,
  //     image: "/asset/media/image/aros-flower.jpg",
  //     title: 'تشریفات',
  //     details: ['ارسال پیامک دعوت',
  //         "مسیریابی با اپ",
  //         'یادبودها',
  //         "عکس عروس داماد",
  //         'مدیریت مهمان‌ها',
  //         "اطمینان از بازدید کارت‌ها"],
  //     cost: "800,000 تومان",
  //     planOption: [
  //         {
  //             id: 1,
  //             option: "شخصی سازی اطلاعات کارت",
  //             disable: "false",
  //         },
  //         {
  //             id: 2,
  //             option: "لینک اختصاصی کارت شما جهت اشتراک گذاری",
  //             disable: "",
  //         },
  //         {
  //             id: 3,
  //             option: "امکان اشتراک گذاری نامحدود لینک",
  //             disable: "",
  //         },
  //         {
  //             id: 4,
  //             option: "ثبت و مدیریت یادبودها",
  //             disable: "",
  //         },
  //         {
  //             id: 5,
  //             option: "امکان ساخت آلبوم عکس",
  //             disable: "disable",
  //         },
  //         {
  //             id: 6,
  //             option: "امکان  قرارگیری عکس تکی عروس و داماد",
  //             disable: "",
  //         },
  //         {
  //             id: 7,
  //             option: "امکان نظرسنجی در مورد شرکت یا عدم شرکت مهمان‌ها",
  //             disable: "",
  //         },
  //         {
  //             id: 8,
  //             option: "مدیریت مهمان ها از طریق پنل",
  //             disable: "",
  //         },
  //         {
  //             id: 9,
  //             option: "ارسال پیامک با لینک اختصاصی برای هر مهمان (حداکثر 100 کارت)",
  //             disable: "",
  //         },
  //         {
  //             id: 10,
  //             option: "اطمینان از بازدید کارت توسط هر مهمان",
  //             disable: "",
  //         },
  //         {
  //             id: 11,
  //             option: "اشتراک گذاری لوکیشن تالار و امکان استفاده در برنامه‌های مسیریابی",
  //             disable: "",
  //         },
  //         {
  //             id: 12,
  //             option: "استفاده از قالب‌های تشریفات",
  //             disable: "",
  //         },
  //         {
  //             id: 13,
  //             option: "پیامک یادآوری روز قبل از مراسم برای مهمان ها",
  //             disable: "disable",
  //         },
  //         {
  //             id: 14,
  //             option: "عدم محدودیت در تعداد کارت‌های ارسالی",
  //             disable: "disable",
  //         },
  //         {
  //             id: 15,
  //             option: "استفاده از قالب‌های لوکس",
  //             disable: "disable",
  //         },
  //     ],
  //     off: "499,000 تومان",
  // },
  {
    id: 2,
    image: "/asset/media/image/aros-halqe.jpg",
    title: "لوکس",
    details: [
      "ارسال پیامک دعوت",
      "مسیریابی با اپ",
      "آلبوم عکس",
      "پیامک یادآوری روز قبل مراسم",
      "عکس عروس داماد",
      "مدیریت مهمان‌ها",
      "اطمینان از بازدید کارت‌ها",
    ],
    cost: "1,000,000",
    planOption: [
      {
        id: 1,
        option: "شخصی سازی اطلاعات کارت",
        disable: "false",
      },
      {
        id: 2,
        option: "لینک عمومی کارت شما جهت اشتراک گذاری",
        disable: "",
      },
      {
        id: 3,
        option: "امکان اشتراک گذاری نامحدود لینک",
        disable: "",
      },
      {
        id: 4,
        option: "ثبت و مدیریت یادبودها",
        disable: "",
      },
      // {
      //     id: 5,
      //     option: "امکان ساخت آلبوم عکس",
      //     disable: "disable",
      // },
      {
          id: 6,
          option: "امکان  قرارگیری عکس تکی عروس و داماد و پس زمینه",
          disable: "",
      },
      {
        id: 7,
        option: "امکان ایجاد نظرسنجی (به عنوان مثال در مورد شرکت یا عدم شرکت مهمان‌ها)",
        disable: "",
      },
      {
        id: 8,
        option: "مدیریت مهمان ها از طریق پنل",
        disable: "",
      },
      {
        id: 9,
        option: "ارسال پیامک با لینک اختصاصی برای هر مهمان (۳۰۰ پیامک رایگان)",
        disable: "",
      },
      {
        id: 10,
        option: "اطمینان از بازدید کارت توسط هر مهمان",
        disable: "",
      },
      {
        id: 11,
        option:
          "اشتراک گذاری لوکیشن تالار و امکان استفاده در برنامه‌های مسیریابی",
        disable: "",
      },
      // {
      //     id: 12,
      //     option: "استفاده از قالب‌های تشریفات",
      //     disable: "",
      // },
      {
        id: 13,
        option: "پیامک یادآوری روز قبل از مراسم برای مهمان ها",
        disable: "",
      },
      {
        id: 14,
        option: "عدم محدودیت در تعداد کارت‌های ارسالی (۳۰۰ sms رایگان و امکان شارژ)",
        disable: "",
      },
      {
        id: 15,
        option: "استفاده از قالب‌های لوکس",
        disable: "",
      },
    ],
    off: "699,000",
  },
];
// export const plansOption = [
//     {
//         id: 258,
//         details: "",
//     },
//     {
//         id: 1,
//         details: "شخصی سازی اطلاعات کارت",
//     },
//     {
//         id: 2,
//         details: "ایجاد و مدیریت یادبودها",
//     },
//     {
//         id: 1,
//         details: "لینک اختصاصی کارت دعوت جهت اشتراک گذاری",
//     },
//     {
//         id: 1,
//         details: "امکان اشتراک گذاری نامحدود لینک دعوت",
//     },
//     {
//         id: 1,
//         details: "ارسال لینک اختصاصی برای هرمهمان با پیامک",
//     },
//     {
//         id: 1,
//         details: "ارسال لینک اختصاصی برای هرمهمان با پیامک",
//     },
// ];

export const optionData = [
  {
    id: 1,
    image: "/asset/media/icon/icon-dinner.webp",
    title: "بازدیدها",
    number: 2500,
  },
  {
    id: 2,
    image: "/asset/media/icon/icon-wedding-save.webp",
    title: "کارت‌های منتشرشده",
    number: 2500,
  },
  {
    id: 3,
    image: "/asset/media/icon/icons-bride.png",
    title: "کاربرها",
    number: 2500,
  },
  {
    id: 4,
    image: "/asset/media/icon/icon-comment.png",
    title: "یادبودها ",
    number: 2500,
  },
];

export const dashboardCardData = [
  {
    id: 1,
    borderColor: "#4834d4",
    backgroundColor: "#686de0",
    image: "/asset/media/icon/wedding-card-close.png",
    title: "کارت‌های ارسالی",
    number: 25,
  },
  {
    id: 2,
    borderColor: "#22a6b3",
    backgroundColor: "#7ed6df",
    image: "/asset/media/icon/wedding-card.png",
    title: "کارت‌های بازدیدشده",
    number: 82,
  },
  {
    id: 3,
    borderColor: "#6ab04c",
    backgroundColor: "#badc58",
    image: "/asset/media/icon/cheers-white.png",
    title: "مهمان‌ها",
    number: 61,
  },
  {
    id: 4,
    borderColor: "#eb4d4b",
    backgroundColor: "#ff7979",
    image: "/asset/media/icon/wedding-day.png",
    title: "روزهای باقی‌مانده",
    number: 32,
  },
];

export const trees = [
  {
    id: 1,
    image: "/asset/media/pic/tree/tree1.svg",
  },
  {
    id: 2,
    image: "/asset/media/pic/tree/tree2.svg",
  },
  {
    id: 3,
    image: "/asset/media/pic/tree/tree3.svg",
  },
  {
    id: 4,
    image: "/asset/media/pic/tree/tree4.svg",
  },
  {
    id: 5,
    image: "/asset/media/pic/tree/tree5.svg",
  },
  {
    id: 6,
    image: "/asset/media/pic/tree/tree6.svg",
  },
  {
    id: 7,
    image: "/asset/media/pic/tree/tree7.svg",
  },
  {
    id: 8,
    image: "/asset/media/pic/tree/tree8.svg",
  },
  {
    id: 9,
    image: "/asset/media/pic/tree/tree9.svg",
  },
  {
    id: 10,
    image: "/asset/media/pic/tree/tree10.svg",
  },
  {
    id: 11,
    image: "/asset/media/pic/tree/tree11.svg",
  },
  {
    id: 12,
    image: "/asset/media/pic/tree/tree12.svg",
  },
];

export const arosDamad = [
  {
    id: 1,
    title: "میلاد و فاطمه",
  },
  {
    id: 2,
    title: "مهشید و علی",
  },
  {
    id: 3,
    title: "آرام و پارسا",
  },
  {
    id: 4,
    title: "سارا و ماهان",
  },
  {
    id: 5,
    title: "ثمین و اشکان",
  },
  {
    id: 6,
    title: "صبا و آرمین",
  },
  {
    id: 7,
    title: "زهرا و رضا",
  },
  {
    id: 8,
    title: "راحله و داوود",
  },
  {
    id: 9,
    title: "ریحانه و محسن",
  },
  {
    id: 10,
    title: "کیمیا و مهرشاد",
  },
  {
    id: 11,
    title: "زینب و نعیم",
  },
  {
    id: 12,
    title: "ساحل و مهرداد",
  },
  {
    id: 13,
    title: "میلاد و فاطمه",
  },
  {
    id: 14,
    title: "مهشید و علی",
  },
  {
    id: 15,
    title: "آرام و پارسا",
  },
  {
    id: 16,
    title: "سارا و ماهان",
  },
  {
    id: 17,
    title: "ثمین و اشکان",
  },
  {
    id: 18,
    title: "صبا و آرمین",
  },
  {
    id: 19,
    title: "زهرا و رضا",
  },
  {
    id: 20,
    title: "راحله و داوود",
  },
  {
    id: 21,
    title: "ریحانه و محسن",
  },
  {
    id: 22,
    title: "کیمیا و مهرشاد",
  },
  {
    id: 23,
    title: "زینب و نعیم",
  },
  {
    id: 24,
    title: "ساحل و مهرداد",
  },
  {
    id: 25,
    title: "میلاد و فاطمه",
  },
  {
    id: 26,
    title: "مهشید و علی",
  },
  {
    id: 27,
    title: "آرام و پارسا",
  },
  {
    id: 28,
    title: "سارا و ماهان",
  },
  {
    id: 29,
    title: "ثمین و اشکان",
  },
  {
    id: 30,
    title: "صبا و آرمین",
  },
  {
    id: 31,
    title: "زهرا و رضا",
  },
  {
    id: 32,
    title: "راحله و داوود",
  },
  {
    id: 33,
    title: "ریحانه و محسن",
  },
  {
    id: 34,
    title: "کیمیا و مهرشاد",
  },
  {
    id: 35,
    title: "زینب و نعیم",
  },
  {
    id: 36,
    title: "ساحل و مهرداد",
  },
  {
    id: 37,
    title: "میلاد و فاطمه",
  },
  {
    id: 38,
    title: "مهشید و علی",
  },
  {
    id: 39,
    title: "آرام و پارسا",
  },
  {
    id: 40,
    title: "سارا و ماهان",
  },
  {
    id: 41,
    title: "ثمین و اشکان",
  },
  {
    id: 42,
    title: "صبا و آرمین",
  },
  {
    id: 43,
    title: "زهرا و رضا",
  },
  {
    id: 44,
    title: "راحله و داوود",
  },
  {
    id: 45,
    title: "ریحانه و محسن",
  },
  {
    id: 46,
    title: "کیمیا و مهرشاد",
  },
  {
    id: 47,
    title: "زینب و نعیم",
  },
  {
    id: 48,
    title: "ساحل و مهرداد",
  },
  // {
  //   id: 49,
  //   title: "میلاد و فاطمه",
  // },
  // {
  //   id: 50,
  //   title: "مهشید و علی",
  // },
  // {
  //   id: 50,
  //   title: "آرام و پارسا",
  // },
  // {
  //   id: 52,
  //   title: "سارا و ماهان",
  // },
  // {
  //   id: 53,
  //   title: "ثمین و اشکان",
  // },
  // {
  //   id: 54,
  //   title: "صبا و آرمین",
  // },
  // {
  //     id: 55,
  //     title: 'زهرا و رضا',
  // },
  // {
  //     id: 56,
  //     title: 'راحله و داوود',
  // },
  // {
  //     id: 57,
  //     title: 'ریحانه و محسن',
  // },
  // {
  //     id: 58,
  //     title: 'کیمیا و مهرشاد',
  // },
  // {
  //     id: 59,
  //     title: 'زینب و نعیم',
  // },
  // {
  //     id: 60,
  //     title: 'ساحل و مهرداد',
  // },
  // {
  //     id: 61,
  //     title: 'میلاد و فاطمه',
  // },
  // {
  //     id: 62,
  //     title: 'مهشید و علی',
  // },
  // {
  //     id: 63,
  //     title: 'آرام و پارسا',
  // },
  // {
  //     id: 64,
  //     title: 'سارا و ماهان',
  // },
  // {
  //     id: 65,
  //     title: 'ثمین و اشکان',
  // },
  // {
  //     id: 66,
  //     title: 'صبا و آرمین',
  // },
  // {
  //     id: 67,
  //     title: 'زهرا و رضا',
  // },
  // {
  //     id: 68,
  //     title: 'راحله و داوود',
  // },
  // {
  //     id: 69,
  //     title: 'ریحانه و محسن',
  // },
  // {
  //     id: 70,
  //     title: 'کیمیا و مهرشاد',
  // },
  // {
  //     id: 71,
  //     title: 'زینب و نعیم',
  // },
  // {
  //     id: 72,
  //     title: ' محد  همین سادات ساحل و مهرداد',
  // },
];

export const comments = [
  { name: "عمه اعظم", comment: "انشالله خوشبخت و عاقبت به خیر باشید." },
  { name: "دایی رضا", comment: "با آرزوی خوشبختی و سعادت برای شما عزیزانم." },
  { name: "زن عمو هانیه", comment: "خوشبختی شما عزیزانم آرزوی همیشگی ماست." },
  {
    name: "22222زن عمو هانیه",
    comment: "خوشبختی شما عزیزانم آرزوی همیشگی ماست.",
  },
];

export const exampleCartData = [
  {
    id: 19,
    wedding_id: 5,
    number_of_guests: 4,
    code: "NxVb",
    guestName: "آقا فرهاد و مریم بانو",
    wedding: {
      id: 7,
      client_id: "6",
      licence_id: "3",
      bride_name: "نبات",
      bride_name_eng: "nabat",
      bride_lastname: "ایزدی",
      groom_name: "عماد",
      groom_name_eng: "emad",
      groom_lastname: "فرهادی",
      description:
        "\u0622\u0646\u062c\u0627 \u06a9\u0647 \u0628\u0627\u0634\u06cc \u0633\u062a\u0627\u0631\u0647 \u0645\u06cc\u200c\u0631\u0648\u06cc\u062f \u0628\u0631 \u0622\u0633\u0645\u0627\u0646\u0634\n\u0628\u0627\u0634 \u062a\u0627 \u0628\u0627\u0634\u0645 \u062a\u06a9\u06cc\u0647 \u0628\u0631 \u0646\u0641\u0633\u200c\u0647\u0627\u06cc\u062a\n\u0647\u0631\u06a9\u062c\u0627 \u0645\u06cc\u200c\u0631\u0648\u06cc \u0647\u0631\u06a9\u062c\u0627 \u0645\u06cc\u200c\u0628\u0631\u06cc\n\u0645\u0646 \u0628\u0627 \u062a\u0648 \u0628\u0627\u0634\u0645",
      date: "2025-01-26",
      from_time: "19:00:00",
      to_time: "24:00:00",
      foods: "\u0634\u0627\u0645 \u0648 \u0634\u06cc\u0631\u06cc\u0646\u06cc",
      deleted_at: null,
      created_at: "2024-09-04T20:43:23.000000Z",
      updated_at: "2024-09-08T12:25:18.000000Z",
      saloon_name:
        "\u0628\u0627\u063a \u062a\u0627\u0644\u0627\u0631 \u0642\u0635\u0631 \u0644\u0646\u0627",
      saloon_address:
        "\u0628\u0648\u0645\u0647\u0646 \u062e\u06cc\u0627\u0628\u0627\u0646 \u062a\u0639\u0627\u0648\u0646 \u067e\u0627\u06cc\u06cc\u0646\u200c\u062a\u0631 \u0627\u0632 \u067e\u0627\u0631\u06a9\u06cc\u0646\u06af \u0635\u062f\u0627\u0642\u062a \u067e\u0644\u0627\u06a9 \u06f5\u06f4",
      waze: "https://waze.com/ul/htnkghw7t2",
      google_map: "https://maps.app.goo.gl/H5qRP3h37KHpfCyW7",
      balad: "https://balad.ir/p/1QoBXHXWmtTlSx",
      neshan: "https://nshn.ir/2erbvksDPxtXQM",
      bride_avatar: null,
      groom_avatar: null,
      background: null,
      bride_lastname_eng: "onsori",
      groom_lastname_eng: "godarzi",
      slug: "yasna-mostafa",
      any_sms_send: "0",
      active: "1",
      show_eng_names: "0",
      show_survey: "1",
      show_memento: "1",
      template_id: "7",
      sms_left: "0",
      totalNumberOfGuests: "106",
      totalCarts: "59",
      visitedCarts: "44",
      totalCartsWithoutSms: "0",
      totalSmsSends: 73,
      daysLeft: 0,
      survey_user_answers: [],
      music: "/asset/media/music.mp3",
      surveys: [
        {
          id: 7,
          question: "پذیرای حضور گرمتان هستیم؟",
          wedding_id: 29,
          deleted_at: null,
          created_at: "2024-11-26T23:55:29.000000Z",
          updated_at: "2024-11-26T23:55:29.000000Z",
          survey_answers: [
            {
              id: 11,
              answer: "بله",
              survey_id: 7,
              deleted_at: null,
              created_at: "2024-11-26T23:55:29.000000Z",
              updated_at: "2024-11-26T23:55:29.000000Z",
              survey_user_answers: [],
            },
            {
              id: 13,
              answer: "خیر",
              survey_id: 7,
              deleted_at: null,
              created_at: "2024-11-26T23:55:29.000000Z",
              updated_at: "2024-11-26T23:55:29.000000Z",
              survey_user_answers: [],
            },
          ],
        },
      ],
      mementos: [
        {
          id: 19,
          wedding_id: "7",
          cart_id: "145",
          text: "\u0627\u06cc\u0634\u0627\u0644\u0627 \u06a9\u0647 \u0647\u0645\u06cc\u0634\u0647 \u062e\u0648\u0634\u0628\u062e\u062a \u0628\u0627\u0634\u06cc\u062f \n\u0648 \u0633\u0644\u0627\u0645\u062a \u0648 \u062a\u0646\u062f\u0631\u0633\u062a \n\u0648 \u0647\u0645\u06cc\u0634\u0647 \u062e\u0646\u062f\u0647 \u0631\u0648 \u0644\u0628\u0627\u062a\u0648\u0646 \u0628\u0628\u06cc\u0646\u0645 \u06a9\u06cc\u0641 \u06a9\u0646\u0645 \n\u062f\u0648\u0633\u062a\u0648\u0646 \u062f\u0627\u0631\u0645",
          show: "1",
          deleted_at: null,
          created_at: "2024-09-17T08:08:00.000000Z",
          updated_at: "2024-09-20T09:28:54.000000Z",
          name: "\u0645\u0631\u06cc\u0645",
          cart: {
            id: 145,
            wedding_id: "7",
            name: "\u0645\u0631\u06cc\u0645 \u0639\u0632\u06cc\u0632",
            mobile: "09199821683",
            number_of_guests: "1",
            deleted_at: null,
            created_at: "2024-09-11T08:14:40.000000Z",
            updated_at: "2024-10-03T10:13:54.000000Z",
            code: "VpgK",
            sms_send: "1",
            visited: "1",
          },
        },
        {
          id: 20,
          wedding_id: "7",
          cart_id: "135",
          text: "\u067e\u06cc\u0648\u0646\u062f\u062a\u0627\u0646 \u0647\u0645\u06cc\u0634\u06af\u06cc \n\u062e\u0648\u0634\u0628\u062e\u062a\u06cc \u0648\u0627\u0642\u0639\u06cc \u062f\u0631 \u062f\u0646\u06cc\u0627 \u0641\u0642\u0637 \u0628\u0627 \u06cc\u06a9 \u0686\u06cc\u0632 \u0645\u06cc\u0633\u0631 \u0645\u06cc\u0634\u0648\u062f. \u062f\u0648\u0633\u062a \u062f\u0627\u0634\u062a\u0646 \u0648 \u062f\u0648\u0633\u062a \u062f\u0627\u0634\u062a\u0647 \u0634\u062f\u0646. \u062e\u0648\u0634\u0628\u062e\u062a\u06cc \u0634\u0645\u0627 \u0622\u0631\u0632\u0648\u06cc \u0642\u0644\u0628\u06cc \u0645\u0627 \u0627\u0633\u062a.",
          show: "1",
          deleted_at: null,
          created_at: "2024-09-17T08:17:27.000000Z",
          updated_at: "2024-09-20T09:28:54.000000Z",
          name: "\u0627\u0645\u06cc\u0631\u062d\u0633\u06cc\u0646",
          cart: {
            id: 135,
            wedding_id: "7",
            name: "\u0627\u0645\u06cc\u0631 \u0639\u0632\u06cc\u0632",
            mobile: "09191358365",
            number_of_guests: "1",
            deleted_at: null,
            created_at: "2024-09-11T08:06:38.000000Z",
            updated_at: "2024-09-26T17:50:51.000000Z",
            code: "GjBn",
            sms_send: "1",
            visited: "1",
          },
        },
        {
          id: 21,
          wedding_id: "7",
          cart_id: "136",
          text: "\u0628\u0627 \u0622\u0631\u0632\u0648\u06cc \u062e\u0648\u0634\u0628\u062e\u062a\u06cc \u0648 \u0633\u0644\u0627\u0645\u062a\u06cc  \u0628\u0631\u0627\u06cc \u0634\u0645\u0627 \u0632\u0648\u062c \u0639\u0632\u06cc\u0632 \u060c \u0627\u0645\u06cc\u062f\u0648\u0627\u0631\u0645 \u062f\u0631 \u067e\u0646\u0627\u0647 \u062e\u062f\u0627\u0648\u0646\u062f \u06cc\u06af\u0627\u0646 \u0632\u0646\u062f\u06af\u06cc \u0633\u0631\u0634\u0627\u0631 \u0627\u0632 \u0645\u0647\u0631 \u0648 \u0645\u062d\u0628\u062a \u0648 \u062e\u0648\u0634\u0628\u062e\u062a\u06cc \u0631\u0648\u0632 \u0627\u0641\u0632\u0648\u0646  \u062f\u0627\u0634\u062a\u0647 \u0628\u0627\u0634\u06cc\u062f \u2026.\n\u062f\u0648\u0633\u062a\u062f\u0627\u0631 \u0634\u0645\u0627 \u0645\u062d\u0633\u0646",
          show: "1",
          deleted_at: null,
          created_at: "2024-09-17T08:54:34.000000Z",
          updated_at: "2024-09-20T09:28:54.000000Z",
          name: "\u0645\u062d\u0633\u0646",
          cart: {
            id: 136,
            wedding_id: "7",
            name: "\u0645\u062d\u0633\u0646 \u0639\u0632\u06cc\u0632",
            mobile: "09351330159",
            number_of_guests: "1",
            deleted_at: null,
            created_at: "2024-09-11T08:07:11.000000Z",
            updated_at: "2024-09-17T08:53:04.000000Z",
            code: "27hr",
            sms_send: "1",
            visited: "1",
          },
        },
        {
          id: 22,
          wedding_id: "7",
          cart_id: "157",
          text: "\u0645\u0645\u0646\u0648\u0646\u0645 \u0627\u0632 \u062f\u0639\u0648\u062a\u062a\u0648\u0646 \u0639\u0632\u06cc\u0632\u0627\u0646 \u0627\u0645\u06cc\u062f\u0648\u0627\u0631\u0645 \u0647\u0645\u06cc\u0634\u0647 \u062a\u0646\u062a\u0648\u0646 \u0633\u0627\u0644\u0645 \u0648 \u062f\u0644\u062a\u0648\u0646 \u062e\u0648\u0634 \u0628\u0627\u0634\u0647 \u062f\u0631 \u06a9\u0646\u0627\u0631 \u0647\u0645",
          show: "1",
          deleted_at: null,
          created_at: "2024-09-17T09:42:59.000000Z",
          updated_at: "2024-09-20T09:28:54.000000Z",
          name: "\u0634\u0627\u062f\u06cc",
          cart: {
            id: 157,
            wedding_id: "7",
            name: "\u0634\u0627\u062f\u06cc \u0648 \u0639\u0644\u06cc\u0631\u0636\u0627 \u0639\u0632\u06cc\u0632",
            mobile: "09194980604",
            number_of_guests: "2",
            deleted_at: null,
            created_at: "2024-09-17T08:37:22.000000Z",
            updated_at: "2024-09-17T18:18:35.000000Z",
            code: "BDmv",
            sms_send: "1",
            visited: "1",
          },
        },
        {
          id: 23,
          wedding_id: "7",
          cart_id: "139",
          text: "\u062a\u0635\u0648\u06cc\u0631 \u0632\u0646\u062f\u06af\u06cc\u062a\u0627\u0646 \u067e\u0631 \u0627\u0632 \u0631\u0646\u06af \u0647\u0627\u06cc \u0632\u06cc\u0628\u0627 \n\u062e\u0648\u0646\u0647 \u062f\u0644\u0647\u0627\u062a\u0648\u0646 \u06af\u0631\u0645\n\u067e\u0631 \u0627\u0632 \u0639\u0634\u0642 \u0648 \u0645\u062d\u0628\u062a\n\u0633\u0641\u0631\u062a\u0648\u0646 \u067e\u0631 \u0627\u0632 \u0628\u0631\u06a9\u062a\n\u0646\u06af\u0627\u0647\u062a\u0648\u0646 \u067e\u0631 \u0627\u0632 \u0627\u0645\u06cc\u062f \n\u0628\u0627\u0647\u0645 \u0634\u062f\u0646 \u062a\u0648\u0646 \u0645\u0628\u0627\u0631\u06a9",
          show: "1",
          deleted_at: null,
          created_at: "2024-09-17T09:46:36.000000Z",
          updated_at: "2024-09-20T09:28:54.000000Z",
          name: "\u0645\u0647\u062a\u0627\u0628",
          cart: {
            id: 139,
            wedding_id: "7",
            name: "\u0645\u062d\u0645\u062f , \u0645\u0647\u062a\u0627\u0628 , \u0645\u0628\u06cc\u0646 \u0648 \u0645\u0647\u06cc\u0627\u0631 \u0639\u0632\u06cc\u0632",
            mobile: "09122840243",
            number_of_guests: "4",
            deleted_at: null,
            created_at: "2024-09-11T08:10:01.000000Z",
            updated_at: "2024-09-20T18:20:59.000000Z",
            code: "6iZB",
            sms_send: "1",
            visited: "1",
          },
        },
        {
          id: 24,
          wedding_id: "7",
          cart_id: "144",
          text: "\u062e\u0648\u0634\u0628\u062e\u062a\u06cc\u00a0\u06cc\u0639\u0646\u06cc\n\n\u0632\u0646\u062f\u06af\u06cc \u0632\u06cc\u0631 \u06cc\u06a9 \u0633\u0642\u0641\n\n\u0628\u0627 \u06a9\u0633\u06cc \u06a9\u0647\n\n\u0639\u0627\u0634\u0642\u0627\u0646\u0647 \u062f\u0648\u0633\u0634 \u062f\u0627\u0631\u06cc\n\n\u0634\u0631\u0648\u0639 \u0628\u0627 \u0647\u0645 \u0628\u0648\u062f\u0646\u062a\u0648\u0646 \u0645\u0628\u0627\u0631\u06a9",
          show: "1",
          deleted_at: null,
          created_at: "2024-09-17T12:01:42.000000Z",
          updated_at: "2024-09-20T09:28:54.000000Z",
          name: "\u0645\u062d\u0645\u062f",
          cart: {
            id: 144,
            wedding_id: "7",
            name: "\u0645\u062d\u0645\u062f \u0639\u0632\u06cc\u0632",
            mobile: "09152593641",
            number_of_guests: "1",
            deleted_at: null,
            created_at: "2024-09-11T08:14:14.000000Z",
            updated_at: "2024-09-17T11:57:50.000000Z",
            code: "WYtG",
            sms_send: "1",
            visited: "1",
          },
        },
        {
          id: 25,
          wedding_id: "7",
          cart_id: "131",
          text: "\u0628\u0647\u062a\u0631\u06cc\u0646\u0647\u0627 \u0631\u0648 \u0627\u0632 \u0635\u0645\u06cc\u0645 \u0642\u0644\u0628\u0645 \u0628\u0631\u0627\u062a\u0648\u0646 \u0622\u0631\u0632\u0648\u0645\u0646\u062f\u0645\u060c\u067e\u0627\u06cc\u062f\u0627\u0631 \u0648 \u062e\u0648\u0634\u0628\u062e\u062a \u0628\u0634\u06cc\u0646 \ud83e\udd73\ud83e\uddff\ud83d\udc70\ud83c\udffb\ud83e\udd35\ud83c\udffb\n\u062f\u0648\u0633\u062a\u062f\u0627\u0631 \u0634\u0645\u0627 \u067e\u0633\u0631 \u062f\u0627\u06cc\u06cc\ud83d\ude0d\ud83e\udd29",
          show: "1",
          deleted_at: null,
          created_at: "2024-09-18T07:29:58.000000Z",
          updated_at: "2024-09-20T09:28:54.000000Z",
          name: "\u0627\u0645\u06cc\u062f",
          cart: {
            id: 131,
            wedding_id: "7",
            name: "\u0627\u0645\u06cc\u062f \u0639\u0632\u06cc\u0632 \u0648 \u0647\u0645\u0633\u0631 \u06af\u0631\u0627\u0645\u06cc",
            mobile: "09392441157",
            number_of_guests: "2",
            deleted_at: null,
            created_at: "2024-09-11T08:02:23.000000Z",
            updated_at: "2024-09-26T15:22:33.000000Z",
            code: "8dqc",
            sms_send: "1",
            visited: "1",
          },
        },
        {
          id: 26,
          wedding_id: "7",
          cart_id: "168",
          text: "\u0627\u0632 \u0635\u0645\u06cc\u0645 \u0642\u0644\u0628 \u0628\u0647\u062a\u0648\u0646 \u062a\u0628\u0631\u06cc\u06a9 \u0645\u06cc\u06af\u0645 \u0648 \u0642\u0634\u0646\u06af \u062a\u0631\u06cc\u0646 \u0627\u062a\u0641\u0627\u0642\u0627\u062a \u0631\u0648 \u0628\u0631\u0627\u062a\u0648\u0646 \u0622\u0631\u0632\u0648 \u0645\u06cc\u06a9\u0646\u0645. \u0627\u0645\u06cc\u062f\u0648\u0627\u0645 \u0639\u0634\u0642\u06cc \u06a9\u0647 \u0628\u0647 \u0647\u0645 \u062f\u0627\u0631\u06cc\u062f\u060c \u062a\u0627 \u0627\u0628\u062f \u067e\u0627\u0628\u0631\u062c\u0627 \u0648 \u0639\u0645\u06cc\u0642 \u062a\u0631 \u0628\u0634\u0647.",
          show: "1",
          deleted_at: null,
          created_at: "2024-09-19T11:41:36.000000Z",
          updated_at: "2024-09-20T09:28:54.000000Z",
          name: "\u062f\u0627\u062f\u0627\u0634 \u0645\u062d\u0633\u0646",
          cart: {
            id: 168,
            wedding_id: "7",
            name: "\u0645\u062d\u0633\u0646 \u0639\u0632\u06cc\u0632",
            mobile: "09227591044",
            number_of_guests: "1",
            deleted_at: null,
            created_at: "2024-09-19T11:13:58.000000Z",
            updated_at: "2024-09-26T15:55:24.000000Z",
            code: "wKEB",
            sms_send: "1",
            visited: "1",
          },
        },
        {
          id: 27,
          wedding_id: "7",
          cart_id: "172",
          text: "\u0627\u0644\u0647\u06cc \u062e\u0648\u0634\u0628\u062e\u062a \u062a\u0631\u06cc\u0646 \u0628\u0627\u0634\u06cc\u0646 \u0631\u0641\u06cc\u0642 \u0642\u062f\u06cc\u0645\u06cc \u0648 \u062f\u0648\u0633\u062a \u0647\u0645\u06cc\u0634\u06af\u06cc \u0645\u0646",
          show: "1",
          deleted_at: null,
          created_at: "2024-09-19T19:25:32.000000Z",
          updated_at: "2024-09-20T09:28:54.000000Z",
          name: "\u0622\u0631\u0645\u06cc\u0646",
          cart: {
            id: 172,
            wedding_id: "7",
            name: "\u0622\u0631\u0645\u06cc\u0646 \u0639\u0632\u06cc\u0632",
            mobile: "09113877322",
            number_of_guests: "1",
            deleted_at: null,
            created_at: "2024-09-19T18:59:29.000000Z",
            updated_at: "2024-09-19T19:27:51.000000Z",
            code: "dEYV",
            sms_send: "1",
            visited: "1",
          },
        },
        {
          id: 28,
          wedding_id: "7",
          cart_id: "128",
          text: "\u0628\u0627 \u0639\u0631\u0636 \u062a\u0628\u0631\u06cc\u06a9\u060c\u0627\u0645\u06cc\u062f\u0648\u0627\u0631\u06cc\u0645 \u0632\u0646\u062f\u06af\u06cc \u067e\u0631 \u0627\u0632 \u0639\u0634\u0642 \u0648 \u0635\u0645\u06cc\u0645\u06cc\u062a \u062f\u0627\u0634\u062a\u0647 \u0628\u0627\u0634\u06cc\u062f.",
          show: "1",
          deleted_at: null,
          created_at: "2024-09-25T08:06:51.000000Z",
          updated_at: "2024-09-25T08:06:51.000000Z",
          name: "\u062d\u0627\u0646\u0627\u060c\u0639\u0644\u06cc\u0631\u0636\u0627",
          cart: {
            id: 128,
            wedding_id: "7",
            name: "\u0639\u0644\u06cc\u0631\u0636\u0627 \u0648 \u0647\u0645\u0633\u0631 \u06af\u0631\u0627\u0645\u06cc",
            mobile: "09124093707",
            number_of_guests: "2",
            deleted_at: null,
            created_at: "2024-09-11T08:00:22.000000Z",
            updated_at: "2024-09-26T08:43:19.000000Z",
            code: "OYJ4",
            sms_send: "1",
            visited: "1",
          },
        },
      ],
    },
  },
];

export const QuestionAi = [
  {
    id: 1,
    title: "What is your name?", // Should be a string
    name: "name",
    type: "text",
    placeholder: "Enter your name",
  },
  {
    id: 2,
    title: "How old are you?", // Should be a string
    name: "age",
    type: "number",
    placeholder: "Enter your age",
  },
  {
    id: 3,
    title: "How old are you99999?", // Should be a string
    name: "age",
    type: "number",
    placeholder: "Enter your age",
  },
  {
    id: 4,
    title: "What is your name?", // Should be a string
    name: "name",
    type: "text",
    placeholder: "Enter your name",
  },
  // More questions...
];
