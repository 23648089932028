import { Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Navigation from "../components/Navigation/Navigation";
import { Helmet } from "react-helmet-async";

const Success = () => {
  return (
    <>
      <Helmet>
        <title>عروسی آنلاین - پرداخت موفق</title>
        <meta name="description" content="پرداخت با موفقیت انجام شد" />
      </Helmet>
      <Box
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Navigation />
        <Box
          style={{
            border: "1px solid #EAEAEA",
            padding: "10px 10px 20px",
            borderRadius: "14px",
            width: "400px",
            maxWidth: "95%",
            textAlign: "center",
          }}
        >
          <Box>
            <svg
              width="140"
              height="140"
              viewBox="0 0 140 140"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M58.3333 99.1668H32.0833C24.0292 99.1668 17.5 92.6377 17.5 84.5835V37.9168C17.5 34.0491 19.0365 30.3398 21.7714 27.6049C24.5063 24.87 28.2156 23.3335 32.0833 23.3335H102.083C110.137 23.3335 116.667 29.8627 116.667 37.9168V58.3335"
                stroke="#EAEAEA"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M99.1697 40.8344H93.334"
                stroke="#1FC64E"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M35 81.6679H40.8358"
                stroke="#1FC64E"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                cx="102.084"
                cy="96.25"
                r="26.25"
                stroke="#EAEAEA"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M110.913 91.0518L99.8878 102.07L93.2598 95.4618"
                stroke="#1FC64E"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M56.7714 71.5619C51.0762 65.8667 51.0762 56.633 56.7714 50.9379C62.4665 45.2427 71.7002 45.2427 77.3954 50.9379"
                stroke="#1FC64E"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Box>
          <Typography
            style={{ margin: "20px 0 30px", color: "#2ecc71" }}
            variant="h5"
          >
            عملیات پرداخت با موفقیت انجام شد
          </Typography>
          <Link
            style={{
              borderRadius: "10px",
              backgroundColor: "#1FC64E",
              padding: "8px 15px",
              color: "#fff",
              display: "inline-block",
            }}
            to="/dashboard"
          >
            برو به داشبورد
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default Success;
