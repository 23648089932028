import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import axios from "axios";
import { useAuth } from "../../../../../auth/authContext";
import { Link } from "react-router-dom";
import DisableLicence from "../../../../../common/DisableLicence";
import { CircularProgress } from "@material-ui/core";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const MusicPage = () => {
  const { token, fetchCartData, cartData } = useAuth();
  const [uploadedMusic, setUploadedMusic] = useState({});
  const [uploadedMusicName, setUploadedMusicName] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (cartData) {
      setUploadedMusic({
        music: cartData?.music || null,
      });
      setUploadedMusicName({
        music_name: cartData?.music_name || null,
      });
    }
  }, [cartData]);

  const handleSendMusic = async () => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      if (uploadedMusic?.music) {
        formData.append("music", uploadedMusic?.music);
      }

      const response = await axios.post(
        `${BASE_URL}/api/licence/current`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response?.data?.message);
      setIsUploading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsUploading(false);
    }
  };

  return (
    <Box className="formInformation">
      {Number(cartData?.licence_id) === 1 ? <DisableLicence /> : ""}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">موزیک پس زمینه</Typography>
          <Typography>حداکثر حجم آپلود 5mb است.</Typography>
          <Typography>
             کات کردن موزیک: {'  '}   
            <a href="https://audiotrimmer.com/" target="_blank">
               audiotrimmer.com 
            </a>
          </Typography>
        </Grid>

        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          item
          xs={12}
          md={12}
        >
          <MusicUpload
            uploadedMusic={uploadedMusic}
            uploadedMusicName={uploadedMusicName}
            setUploadedMusic={setUploadedMusic}
            isUploading={isUploading}
            setUploadedMusicName={setUploadedMusicName}
            setIsUploading={setIsUploading}
            token={token}
            cartDataMusic={cartData?.music}
          />
        </Grid>

        <Grid
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
          item
          xs={12}
        >
          <Button
            onClick={handleSendMusic}
            className="saveInformation"
            type="submit"
            variant="contained"
            color="primary"
          >
            ذخیره
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MusicPage;

export const MusicUpload = ({
  uploadedMusic,
  uploadedMusicName,
  setUploadedMusic,
  isUploading,
  setUploadedMusicName,
  setIsUploading,
  token,
  cartDataMusic,
}) => {
  const maxFileSize = 5 * 1024 * 1024; // 5MB

  // Dropzone settings
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "audio/*": [],
    },
    maxSize: maxFileSize,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length) {
        handleMusicUpload(acceptedFiles[0]);
      }
      if (rejectedFiles.length) {
        toast.error("File size must be less than 3MB");
      }
    },
  });

  // Handle the music file upload
  const handleMusicUpload = (file) => {
    if (file.size > maxFileSize) {
      toast.error("File size exceeds 5MB!");
      return;
    }
    setUploadedMusic((prevState) => ({
      ...prevState,
      music: file,
    }));
  };

  // Handle the music file removal
  const handleRemoveMusic = async () => {
    if (!cartDataMusic) {
      // اگر فایل فقط در state باشد (انتخاب شده ولی آپلود نشده)
      setUploadedMusic({ music: null });
      setUploadedMusicName({ music_name: null });
      toast.info("فایل حذف شد.");
      return;
    }

    try {
      // ارسال درخواست برای حذف موزیک
      const response = await axios.post(
        `${BASE_URL}/api/licence/current`,
        { delete_music: true },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // موفقیت در حذف موزیک
      toast.success(response?.data?.message);
      setUploadedMusic({ music: null });
      setUploadedMusicName({ music_name: null });
    } catch (error) {
      // خطا در حذف موزیک
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Box
        {...getRootProps()}
        className={`upload-zone ${isDragActive ? "active" : ""}`}
        p={2}
        border={1}
        borderColor="gray"
        borderRadius={4}
        textAlign="center"
        width="100%"
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography>موسیقی خود را اینجا قرار دهید...</Typography>
        ) : (
          <Typography>برای آپلود موسیقی کلیک کنید یا فایل را بکشید</Typography>
        )}
      </Box>
      {uploadedMusic.music && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            marginTop: "15px",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ textAlign: "left" }}>
            {uploadedMusicName.music_name || uploadedMusic.music.name}
          </Typography>
          <Button
            // edge="end"
            // aria-label="delete"
            sx={{
              border: "1px solid rgb(213 43 43)",
              borderRadius: "5px",
              padding: "3px 10px",
              marginRight: "8px",
            }}
            onClick={handleRemoveMusic}
          >
            <DeleteIcon color="error" />
            <Typography sx={{ color: "rgb(213 43 43)" }}>حذف</Typography>
          </Button>
        </Box>
      )}

      {isUploading && (
        <Box
          sx={{
            marginTop: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography sx={{ color: "#3f51b5", marginTop: "8px" }}>
            در حال انجام ...
          </Typography>
        </Box>
      )}
    </>
  );
};
