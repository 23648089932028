import { CircularProgress } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";

const ChangeMusic = ({ music, isExample = false }) => {
  const [playMusic, setPlayMusic] = useState(false);
  const [audioSrc, setAudioSrc] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    if (playMusic && audioRef.current) {
      setIsLoading(true);
      audioRef.current.play().then(() => {
        setIsLoading(false);
      });
    } else if (audioRef.current) {
      audioRef.current.pause();
    }
  }, [playMusic]);

  const handleMusic = () => {
    if (!audioSrc && music) {
      if (isExample) {
        setAudioSrc(music);
      } else {
        setAudioSrc(`https://api.aroosi.online/storage/${music}`);
      }
    }
    setPlayMusic((prev) => !prev);
    setIsClicked(true);
  };

  return (
    <>
      {music ? (
        <div
          className={`playMusicBox ${isClicked ? "smallBox" : "animateBox"}`}
        >
          <div className="backgroundOverlay"></div>
          <audio ref={audioRef} src={audioSrc} loop></audio>
          <button onClick={handleMusic}>
            {isLoading ? (
              <CircularProgress />
            ) : !playMusic ? (
              <img alt="Play/Pause Music" src="/asset/media/icon/music.png" />
            ) : (
              <img
                alt="Play/Pause Music"
                src="/asset/media/icon/no-music.png"
              />
            )}
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ChangeMusic;
