import { useEffect, useState } from "react";
import "../Dashboard/DashboardPage.css";
import Section2Page from "./components/Section2";
import Section3Page from "./components/Section3/Section3";
import Section5Page from "./components/Section5";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/authContext";
import Section1Page from "./components/TopDashboard";
import MementoPage from "./components/Memento";
import SurveriesPage from "./components/Surveries";
import PlanSms from "./components/planSms/PlanSms";
import { Helmet } from "react-helmet-async";

const DashboardPage = () => {
  const defaultSection = localStorage.getItem("activeSection") || "section1";
  const [activeSection, setActiveSection] = useState(defaultSection);
  const { isAuthenticated, cartData, logout } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    localStorage.setItem("activeSection", activeSection);
  }, [activeSection]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSectionClick = (sectionId) => {
    setActiveSection(sectionId);
  };
  const handleSectionMobileClick = (sectionId) => {
    setActiveSection(sectionId);
    toggleSidebar();
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <>
    <Helmet>
    <title>عروسی آنلاین - داشبورد</title>
    <meta name="description" content="داشبورد عروسی آنلاین" />
  </Helmet>
    <div className="dashboardPage">
      <button className="hambergerBtn" onClick={toggleSidebar}>
        ☰
      </button>

      <nav className="sidebarDashboard">
        <ul>
          <li
            className={activeSection === "section1" ? "active" : ""}
            onClick={() => handleSectionClick("section1")}
          >
            داشبورد
          </li>
          {/* <li  className={activeSection === "section2" ? "active" : ""} onClick={() => handleSectionClick('section2')}>ویرایش مشخصات کاربر</li> */}
          <li
            className={activeSection === "section3" ? "active" : ""}
            onClick={() => handleSectionClick("section3")}
          >
            مدیریت کارت عروسی
          </li>
          <li
            className={activeSection === "section5" ? "active" : ""}
            onClick={() => handleSectionClick("section5")}
          >
            لیست مهمان ها
          </li>
          <li
            className={activeSection === "MementoPage" ? "active" : ""}
            onClick={() => handleSectionClick("MementoPage")}
          >
            یادبودها
          </li>
          <li
            className={activeSection === "SurveriesPage" ? "active" : ""}
            onClick={() => handleSectionClick("SurveriesPage")}
          >
            نظرسنجی‌ها
          </li>
          {Number(cartData?.licence_id) !== 1 ? (
            <li
              className={activeSection === "PlanSms" ? "active" : ""}
              onClick={() => handleSectionClick("PlanSms")}
            >
              شارژ SMS
            </li>
          ) : (
            <></>
          )}

          {cartData?.template_id ? (
            <li className="showCard">
              <Link to="/show-my-card" target="_blank">
                نمایش کارت
                <img src="/asset/media/pic/icon/invitation-card.png" alt="" />
              </Link>
            </li>
          ) : (
            <></>
          )}
          {isAuthenticated && (
            <button type="button" className="logOut" onClick={handleLogout}>
              خروج
            </button>
          )}
        </ul>
      </nav>

      {isSidebarOpen && <div className="overlay" onClick={toggleSidebar}></div>}
      <nav className={`sidebarDashboardMobile ${isSidebarOpen ? "open" : ""}`}>
        <ul>
          <li
            className={activeSection === "section1" ? "active" : ""}
            onClick={() => handleSectionMobileClick("section1")}
          >
            داشبورد
          </li>
          {/* <li  className={activeSection === "section2" ? "active" : ""} onClick={() => handleSectionMobileClick('section2')}>کاربر</li> */}
          <li
            className={activeSection === "section3" ? "active" : ""}
            onClick={() => handleSectionMobileClick("section3")}
          >
            مدیریت کارت عروسی
          </li>
          <li
            className={activeSection === "section5" ? "active" : ""}
            onClick={() => handleSectionMobileClick("section5")}
          >
            لیست مهمان ها
          </li>
          <li
            className={activeSection === "MementoPage" ? "active" : ""}
            onClick={() => handleSectionMobileClick("MementoPage")}
          >
            یادبودها
          </li>
          <li
            className={activeSection === "SurveriesPage" ? "active" : ""}
            onClick={() => handleSectionMobileClick("SurveriesPage")}
          >
            نظرسنجی‌ها
          </li>
          {Number(cartData?.licence_id) !== 1 ? (
            <li
              className={activeSection === "PlanSms" ? "active" : ""}
              onClick={() => handleSectionClick("PlanSms")}
            >
              شارژ SMS
            </li>
          ) : (
            <></>
          )}

          {cartData?.template_id ? (
            <li className="showCard">
              <Link to="/show-my-card" target="_blank">
                نمایش کارت
                <img src="/asset/media/pic/icon/invitation-card.png" alt="" />
              </Link>
            </li>
          ) : (
            <></>
          )}
          {isAuthenticated && (
            <button type="button" className="logOut" onClick={handleLogout}>
              خروج
            </button>
          )}
        </ul>
        <button className="close" onClick={toggleSidebar}>
          {/* <img alt="" className="" src="/asset/media/pic/icon/close.png" /> */}
          <span>&#10006;</span>
        </button>
      </nav>
      <main>
        {activeSection === "section1" && <Section1Page />}
        {activeSection === "section2" && <Section2Page />}
        {activeSection === "section3" && <Section3Page />}
        {activeSection === "section5" && <Section5Page />}
        {activeSection === "MementoPage" && <MementoPage />}
        {activeSection === "SurveriesPage" && <SurveriesPage />}
        {activeSection === "PlanSms" && <PlanSms />}
      </main>
    </div>
    </>
  );
};

export default DashboardPage;
