import Layout from "../Layout/Layout";
import PlansComponent from "../components/main/plans";
import OptionsComponent from "../components/main/options";
import * as data from "../data.js";
import BannerComponent from "../components/main/banner";
import AboutUsComponent from "../components/main/aboutUs";
import WhyUsComponent from "../components/main/whyUs";
import TreeComponent from "../components/main/tree";
import { Helmet } from "react-helmet-async";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>عروسی آنلاین</title>
        <meta
          name="description"
          content="در مهمترین روز زندگیتون کنارتونیم"
        />
      </Helmet>
      <Layout>
        <BannerComponent />
        <AboutUsComponent />
        <WhyUsComponent />
        <PlansComponent />
        {/* <TreeComponent /> */}
        {/* <main className="">
                <section className="blogList">
                    {data?.blogs?.map((blog) => {
                        return (
                            <div className="blog" key={blog.id}>
                                {blog.name}\
                                {blog.desp}
                            </div>
                        );
                    })}
                </section>
                <OptionsComponent /> 
            </main> */}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            referrerpolicy="origin"
            id="rgvjjzpeapfujzpeapfujxlz"
            // style="cursor:pointer"
            onclick='window.open("https://logo.samandehi.ir/Verify.aspx?id=375751&p=xlaojyoedshwjyoedshwrfth", "Popup","toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")'
            alt="logo-samandehi"
            src="https://logo.samandehi.ir/logo.aspx?id=375751&p=qftiyndtujynyndtujynnbpd"
          />
          <a
            referrerpolicy="origin"
            target="_blank"
            href="https://trustseal.enamad.ir/?id=552018&Code=X1EDCnAIPyCG3iMwQsoBNrn7WII01iAe"
          >
            <img
              referrerpolicy="origin"
              src="https://trustseal.enamad.ir/logo.aspx?id=552018&Code=X1EDCnAIPyCG3iMwQsoBNrn7WII01iAe"
              alt=""
              code="X1EDCnAIPyCG3iMwQsoBNrn7WII01iAe"
            />
          </a>
        </div>
      </Layout>
    </>
  );
};

export default HomePage;
