import axios from "axios";
import Layout from "../../Layout/Layout";
import { useCart, useCartActions } from "../../Providers/CartProvider";
import "./cartPages.css";
import { useAuth } from "../../auth/authContext";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getLicenceInfo } from "../../services/licence";
import { formatNumberWithCommas } from "../../Utils/formatNumberWithCommas";
import { Button } from "@mui/material";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const CartPage = () => {
  const { planId } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();
  const [offCode, setOffCode] = useState("");
  const [offDetails, setOffDetails] = useState({});
  const [licenceInfo, setLicenceInfo] = useState([]);
  const [cart, setCart] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getLicenceInfo();
        setLicenceInfo(data);
      } catch (err) {
      } finally {
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(storedCart);
  }, []);

  const removeCart = (cartItem) => {
    const updatedCart = cart.filter((item) => item.id !== cartItem.id);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setCart(updatedCart);
  };

  // const dispatch = useCartActions();

  // const removeCart = (cartItem) => {
  //     dispatch({ type: "REMOVE_CARTITEM", payload: cartItem })
  // }

  const handleOff = () => {
    axios
      .post(
        `${BASE_URL}/api/pay/calculate-price`,
        { code: offCode, licence_id: cart[0]?.id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("کد تخفیف با موفقیت ثبت شد.");
        setOffDetails(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setOffCode("");
      });
  };

  function handlePayClick() {
    setIsLoading(true);

    const payload = {
      licence_id: cart[0]?.id,
      ...(offCode && { code: offCode }),
    };

    axios
      .post(`${BASE_URL}/api/pay`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const actionUrl = response?.data?.action;
        if (actionUrl) {
          window.location.href = actionUrl;
        } else {
          toast.error("Action URL not found");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  if (!cart.length) {
    return (
      <Layout>
        <main className="container" style={{ margin: "100px 0" }}>
          <h2 style={{ color: "red" }}>هیچ پکیجی انتخاب نشده است</h2>
        </main>
      </Layout>
    );
  }

  return (
    <Layout>
      <main className="container ">
        <div className="row cartContainer">
          <div className="col-12">
            <h2>سبد خرید</h2>
            <p>* لطفا قبل از زدن دکمه پرداخت فیلترشکن را خاموش کنید.</p>
            <div className="cartItem">
              <div className="text">پکیج {cart[0]?.title}</div>
              <div className="cost">{cart[0]?.cost}</div>
              {/* <button onClick={() => removeCart(cart[0])}>حذف</button> */}
            </div>
            {/* <div className="cartItemOff">
                  <div className="text">قیمت با تخفیف</div>
                  <div className="cost">{cart[0]?.off}</div>
                </div> */}
          </div>

          <div className="col-12">
            <div className="boxInput">
              <input
                type="text"
                placeholder="کد تخفیف"
                value={offCode}
                onChange={(e) => setOffCode(e.target.value)}
              />
              <button type="submit" onClick={handleOff}>
                ثبت
              </button>
            </div>
            {offDetails?.discount_percent && (
              <span style={{ color: "green" }}>
                {offDetails?.discount_percent} % تخفیف خدمت شما :)
              </span>
            )}
            {offDetails?.discount_amount && (
              <span style={{ color: "green" }}>
                {formatNumberWithCommas(offDetails?.discount_amount)} تومان
                تخفیف خدمت شما :)
              </span>
            )}
          </div>

          <div className="col-12 d-flex justify-content-between mt-5">
            <h5 style={{ color: "red" }}>قیمت نهایی :</h5>
            <h5 style={{ color: "red" }}>
              {formatNumberWithCommas(offDetails?.price_with_off) ||
                formatNumberWithCommas(licenceInfo[1]?.price)}{" "}
              تومان
            </h5>
          </div>

          <div className="col-12 mt-3">
            <Button
              type="button"
              className="pay"
              onClick={handlePayClick}
              disabled={isLoading}
            >
              پرداخت
            </Button>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default CartPage;
