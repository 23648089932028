import { Box, Grid, TextField, Typography } from "@mui/material";
import { Switch } from "@material-ui/core";
import "../../../DashboardPage.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import CustomSelector from "../../../../../components/Selector/CustomSelector";
import { useAuth } from "../../../../../auth/authContext";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const options = [
  {
    id: 1,
    label: "Option 1",
    value: `به سنت عشق گرد هم می آییم
    آنجا که دوست داشتن تنها کلام زندگی است`,
  },
  {
    id: 2,
    label: "Option 2",
    value: `از صدای سخن عشق ندیدم خوشتر
    یادگاری که در این گنبد دوار بماند.`,
  },
  {
    id: 3,
    label: "Option 3",
    value: `ای کاش عشق را زبان سخن بود
    آغاز زندگی مشترک خویش را جشن می گیرند
    با تشریف فرمایی خود سرافرازمان فرمایید.`,
  },
  {
    id: 4,
    label: "Option 4",
    value: `بار سفر بسته‌ایم به قصد دریای همدلی
        در پگاه آغازین این سفر همراهمان باشید`,
  },
  {
    id: 5,
    label: "Option 5",
    value: `ساقی به نور باده برافروز جام ما
        مطرب بگو که کار جهان شد به کام ما
        هرگز نمیرد آنکه دلش زنده شد به عشق
        ثبت است بر جریده عالم دوام ما`,
  },
  {
    id: 6,
    label: "Option 6",
    value: `انگار تمام آسمان به این پنجره آمده است
        که سقف کوچک مان چنین پرستاره است
        فردای سبز   آرزویی محال نیست
        وقتی شانه های اعتماد
        پناه مرغان مهاجر است
        از امشب تا خورشید راهی نیست`,
  },
  {
    id: 7,
    label: "Option 7",
    value: `حافظ شب هجران شد
        بوی خوش وصل آمد شادیت مبارک باد
        ای عاشق شیدایی`,
  },
  {
    id: 8,
    label: "Option 8",
    value: `و حالا ما در اغاز سفریم….
        سفری تا بینهایت….سفری تا انتهای زندگی…..
        به امید با هم رفتن و با هم ماندن….
        تا جاییکه هیچ اشکی از چشمی نبارد جز به شوق….
        در اغاز این سفر همراهیمان کنید….`,
  },
  {
    id: 9,
    label: "Option 9",
    value: `بنام خدای عشق
        کنار آشنایی تو آشیانه می‌کنم
        فضای آشیانه را پر از کرانه می ‌کنم
        کسی سئوال می‌کند به خاطر چه زنده‌ای
        و من برای زندگی تو را بهانه می‌کنم
        امیدواریم که گل وجودتان زیباترین نقش باغ خاطره این روز باشید`,
  },
];

const TextPage = () => {
  const [textCard, setTextCard] = useState("");
  const { token, fetchCartData, cartData } = useAuth();
  const [textSide, setTextSide] = useState(0);

  useEffect(() => {
    setTextCard(cartData?.description);
    setTextSide(Number(cartData?.align) || 0);
  }, [cartData]);

  const handleSelection = (selectedValue) => {
    setTextCard(selectedValue);
  };

  const handleInputChange = (value) => {
    setTextCard(value);
  };

  const initialValues = {
    textCard: cartData?.description || "",
    textAlign: Number(cartData?.align),
  };

  const validationSchema = Yup.object({
    textCard: Yup.string(),
  });

  const handleSubmit = (values) => {
    values.textCard = textCard ? textCard : "";

    axios
      .post(
        `${BASE_URL}/api/licence/current`,
        {
          description: values?.textCard,
          align: values?.textAlign,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message);
        fetchCartData();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form className="formInformation">
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">متن اصلی کارت دعوت :</Typography>
              </Grid>
              <Grid item xs={12} sx={{paddingTop:'3px !important'}}>
                <Typography variant="p">
                  متن مورد نظر خود را بنویسید یا از متن های زیر انتخاب کنید
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{paddingTop:'5px !important'}}>
                <TextField
                  value={textCard}
                  onChange={(e) => handleInputChange(e?.target?.value)}
                  placeholder="الهه عشق به انسانها آموخت که عاشقانه زندگی کنند
          و با پیوند همیشه پایداری که سوگندش را در قلبهایمان جاودانه کرده‌اند، 
          و به هم در آمیزند و یکی شوند،
          چه شادمانه است فریاد گونه این سوگند"
                  multiline
                  rows={8}
                  variant="outlined" // Use an outlined variant
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  InputProps={{
                    style: { fontSize: "14px" },
                  }}
                />
              </Grid>

              <Grid item xs={12} sx={{paddingTop:'3px !important'}}>
                <CustomSelector
                  options={options}
                  placeholder={"متن مورد نظر خود را انتخاب کنید"}
                  onSelection={handleSelection}
                  labelName="value"
                  valueName="value"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="p">راست چین</Typography>
                <Switch
                  name="textAlign"
                  value="N"
                  checked={values?.textAlign === 1}
                  onChange={(event, checked) => {
                    setFieldValue("textAlign", checked ? 1 : 0);
                    setTextSide(!textSide);
                  }}
                />
                {/* 0 وسط چین
              1 راست چین */}
                <Typography variant="p">وسط چین</Typography>
              </Grid>

              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "15px",
                }}
                item
                xs={12}
              >
                <button className="saveInformation" type="submit">
                  ذخیره
                </button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default TextPage;
