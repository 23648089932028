const convertToEnglishNumbers = (str) => {
    const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
    let englishStr = str;
  
    persianNumbers.forEach((num, index) => {
      englishStr = englishStr.replace(num, index);
    });
  
    return englishStr;
  };

  export default convertToEnglishNumbers;
  