import "./CardPage8.css";
import Section1Page from "./components/Section1";
import Section2Page from "./components/Section2";
import Section3Page from "./components/Section3";
import Section4Page from "./components/section4";
import Section7Page from "./components/Section7";
import Section6Page from "./components/Section6";
import CardPageTemplate from "../CardPageTemp";
import CardPageTemplateGuest from "../CardPageTempGuest";

const CardPageGuest8 = ({ guestCart }) => {
  const sections = [
    { id: "section4", label: "زمان", component: Section4Page, showInNav: true },
    { id: "section1", label: "کارت", component: Section1Page, showInNav: true },
    {
      id: "section2",
      label: "لوکیشن",
      component: Section2Page,
      showInNav: true,
    },
    {
      id: "section3",
      label: "یادبود",
      component: Section3Page,
      showInNav: true,
    },
    {
      id: "section7",
      label: "نظرسنجی",
      component: Section7Page,
      showInNav: true,
    },
    {
      id: "section6",
      label: "",
      component: Section6Page,
      showInNav: false,
    },
  ];

  return (
    <CardPageTemplateGuest
      guestCart={guestCart}
      sections={sections}
      className="cardPage8"
    />
  );
};

export default CardPageGuest8;
